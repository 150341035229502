
<script setup>
import loading from "~/components/loading.vue";
const isLoading = ref(true);

onMounted(() => {
  isLoading.value = false;
});

</script>
<template>
  <div>
    <loading v-if="isLoading" />
    <slot />
  </div>
</template>

<style>

</style>

